// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Sz0CUWvJT", "SfhW7aU4H", "vKdeiOGBp", "ZIOdqLmYr", "GMcYnGVUQ"];

const variantClassNames = {GMcYnGVUQ: "framer-v-ux5wk5", SfhW7aU4H: "framer-v-6lk4to", Sz0CUWvJT: "framer-v-1m2w1xj", vKdeiOGBp: "framer-v-10yo1mw", ZIOdqLmYr: "framer-v-1ml8wlq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "Sz0CUWvJT", "Variant 2": "SfhW7aU4H", "Variant 3": "vKdeiOGBp", "Variant 4": "ZIOdqLmYr", "Variant 5": "GMcYnGVUQ"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, uqq2MnpFd: title ?? props.uqq2MnpFd ?? "NAV", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Sz0CUWvJT"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, uqq2MnpFd, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Sz0CUWvJT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-kl9AZ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1m2w1xj", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Sz0CUWvJT"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({GMcYnGVUQ: {"data-framer-name": "Variant 5"}, SfhW7aU4H: {"data-framer-name": "Variant 2"}, vKdeiOGBp: {"data-framer-name": "Variant 3"}, ZIOdqLmYr: {"data-framer-name": "Variant 4"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p>NAV</motion.p></React.Fragment>} className={"framer-luopiq"} layoutDependency={layoutDependency} layoutId={"wZ24OzE8l"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={uqq2MnpFd} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-kl9AZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kl9AZ .framer-ho0dg3 { display: block; }", ".framer-kl9AZ .framer-1m2w1xj { height: 19px; overflow: hidden; position: relative; width: 33px; }", ".framer-kl9AZ .framer-luopiq { flex: none; height: auto; left: 48%; position: absolute; top: 47%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 33
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SfhW7aU4H":{"layout":["fixed","fixed"]},"vKdeiOGBp":{"layout":["fixed","fixed"]},"ZIOdqLmYr":{"layout":["fixed","fixed"]},"GMcYnGVUQ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"uqq2MnpFd":"title"}
 * @framerImmutableVariables false
 */
const FramerWqBgbUPAZ: React.ComponentType<Props> = withCSS(Component, css, "framer-kl9AZ") as typeof Component;
export default FramerWqBgbUPAZ;

FramerWqBgbUPAZ.displayName = "1";

FramerWqBgbUPAZ.defaultProps = {height: 19, width: 33};

addPropertyControls(FramerWqBgbUPAZ, {variant: {options: ["Sz0CUWvJT", "SfhW7aU4H", "vKdeiOGBp", "ZIOdqLmYr", "GMcYnGVUQ"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}, uqq2MnpFd: {defaultValue: "NAV", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerWqBgbUPAZ, [])